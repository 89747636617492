import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import contact_items from '../data/contact_items.json';
import { useForm, ValidationError } from '@formspree/react';
import Seo from '../components/seo';
import { useForm as useFormHook } from "react-hook-form";

export default function Contact() {
    const [state, handleSubmit] = useForm("mqkwgjno");
    const { register, handleSubmit: handleSubmitHook, formState: { errors } } = useFormHook();

    return (
        <Layout>
            <Seo title="Contact" />
            <div id="contact_section" className="pb-8 mt-0">
                <div className="flex flex-col items-center mt-4 lg:mt-16">
                    <div className="w-11/12 md:w-5/7 lg:w-1/2">
                        <h4 className="head-font font-semibold text-5xl text-dark-tf text-center">Contact Us</h4>
                        <p className="text-xl 4xl:text-2xl text-dark-tf text-center mt-1">We'd love to work with you!</p>
                        <div className="rounded-3xl bg-white shadow-sm py-10 px-10 mt-10">
                            {state.succeeded &&
                                <div className="text-center">
                                    <div className="flex justify-center">
                                        <h4 className="text-3xl font-bold text-darkblue-tf mr-3 ">Message sent!</h4>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-darkblue-tf" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                    <p className="text-xl 4xl:text-2xl text-dark-tf mt-3">Thank you for getting in touch with us. We will get back to you as soon as possible.</p>
                                </div>
                            }
                            {!state.succeeded &&
                                <form className="grid grid-cols-1 gap-6"
                                    onSubmit={handleSubmitHook(handleSubmit)}
                                >
                                    <label className="block">
                                        <span className="4xl:text-2xl font-bold text-darkblue-tf">Name</span>
                                        <input type="text" name="name" id="name" {...register("name", { required: true })}
                                            className="4xl:text-2xl mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-darkblue-tf focus:ring focus:ring-darkblue-tf focus:ring-opacity-30" placeholder="" />
                                        {errors.name && <p class="mt-1 text-sm text-red-400">Please let us know your name.</p>}
                                    </label>
                                    <label className="block" htmlFor="email">
                                        <span className="4xl:text-2xl font-bold text-darkblue-tf">Email</span>
                                        <input type="email" name="_replyto" id="_replyto" {...register("_replyto", { required: true, pattern: /^\S+@\S+$/i })}
                                            className="4xl:text-2xl mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-darkblue-tf focus:ring focus:ring-darkblue-tf focus:ring-opacity-30" placeholder="john@example.com" />
                                        {errors._replyto && <p class="mt-1 text-sm text-red-400">Please enter your email address.</p>}
                                        {state.errors.filter(x => x.field == '_replyto')[0] && <p class="mt-1 text-sm text-red-400">Kindly enter a valid email address.</p>}
                                    </label>
                                    <div className="block">
                                        <span className="4xl:text-2xl font-bold text-darkblue-tf">Which products and/or services are you interested in?</span>
                                        {errors.products && <p class="text-sm text-red-400">Kindly check "Others" if your message is not specific to any of our products. </p>}
                                        <div className="mt-2 grid grid-cols-1 sm:grid-cols-2  gap-x-4">
                                            {contact_items.map((item) => (
                                                <div key={item}>
                                                    <label className="inline-flex items-center">
                                                        <input name="products" id="products"  {...register("products", { required: true })}
                                                            className="form-checkbox rounded-full text-lg" value={item} type="checkbox" />
                                                        <span className="ml-2 text-lg 4xl:text-2xl ">{item}</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <label className="block">
                                        <span className="4xl:text-2xl font-bold text-darkblue-tf">Message</span>
                                        <textarea name="message" id="message" {...register("message", { required: true })}
                                            className="4xl:text-2xl mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-darkblue-tf focus:ring focus:ring-darkblue-tf focus:ring-opacity-30" rows="3"></textarea>
                                        {errors.message && <p class="mt-1 text-sm text-red-400">How can we help?</p>}
                                    </label>

                                    <div className="mt-8 flex lg:mt-8 w-full justify-center md:justify-end">
                                        <div className="inline-flex rounded-md shadow-xl shadow-blue-tf/20 w-full md:w-auto">
                                            <button type="submit" disabled={state.submitting} className="w-full md:w-auto inline-flex items-center justify-center px-14 py-3 border border-transparent text-l md:text-lg 4xl:text-2xl font-semibold rounded-md text-white bg-blue-tf hover:bg-darkblue-tf">
                                                {state.submitting ? `Sending...` : `Send Message`}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
